import React,{useEffect} from "react";
import { Helmet } from "react-helmet";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import constant from "../constant/constant";
import {fetchBannerData} from "../reducer/thunks"
import { TypeAnimation } from "react-type-animation";
import Carousel from 'react-bootstrap/Carousel';
import Banners from "../componets/Banners";

export default function About() {

  const dispatch = useDispatch()

  const {
    data,
    loading: bannerLoading,
    error: bannerError,
  } = useSelector((state) => state.data);

  document.title = "Local Heros";
  document.getElementsByTagName("META")[2].content = "Local Heros";


  useEffect(() => {
    dispatch(fetchBannerData());
    //   dispatch(fetchProductData());
  }, []);
  return (
    <>
      <Helmet>
        <title>Local Heros</title>
        <meta
          name="description"
          content="This is a description of your page."
        />
        <meta name="keywords" content="local heroes, community, volunteer" />
      </Helmet>
      <Header />
     
         <Banners/>

      <section className="py-5 bg-full-dark-1">
        <div className="container about-box">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              <h2 className="theme-color">Our Story</h2>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-md-6 p-0">
              <img
                src={require("../assets/images/our-story-rice.png")}
                className="w-100"
              />
            </div>
            <div className="col-md-6 bg-orange p-0 d-flex align-items-center justify-content-center">
              <div className=" px-md-4 px-3 ">
                {/* <h2 className="text-dark fs-1 fw-semibold text-start">
                  Lorem ipsum
                </h2> */}
                <p className="lh-md text-left abt-cont">
                Welcome to our journey at Mysore Biryani, where tradition and innovation converge to create a culinary experience unlike any other. It all started with a simple Sunday experiment that my spouse and I embarked on, driven by our shared passion for wholesome meals and a desire to reinvent the timeless favorite, Biryani, into a healthier rendition. Drawing inspiration from the rich culinary heritage of Mysore, we meticulously crafted a recipe that strikes the perfect balance between tradition and modern sensibilities, ensuring that each bite is infused with love and authenticity. As word of our delectable creation spread, so did the demand, turning our humble experiment into a thriving culinary venture. Today, we invite you to join us in savoring the taste of tradition and homemade goodness with every plate of Mysore Biryani, as we continue to push the boundaries of flavor and tradition, one dish at a time.
                </p>
              </div>
            </div>
          </div>
         
        </div>
      </section>

      


      <section className="py-5 bg-full-dark-1">
        <div className="container">
          <div className="row justify-content-center text-white">
            <div className="col-md-12 text-center mb-4">
              <h2 className='theme-color'>Blogs</h2>
            </div>
            {data &&
              data.Faqs &&
              data.Faqs.slice(0, 3).map((item) => (
                <div className="col-md-4 text-center mb-5 mb-md-0">
                  <div className="blog-box mb-4">
                    <img
                      src={`${item.imageUrl}`}
                      className="img-fluid mb-3"
                    />
                    <div className="p-2">
                    <h3 className="font-size-blogs blogspace">{item.name}</h3>
                    <p className="pb-3 blogspace mt-3">
                      {item.description}{" "}
                    </p>
                      </div>
                   
                  </div>
                  <Link to={`/blog/${item._id}`} className="readmore-button">
                    {" "}
                    Read more{" "}
                  </Link>
                </div>
              ))}
           




          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
