import React, { useEffect,useRef } from "react";
import { Helmet } from "react-helmet";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";
import { fetchBannerData } from "../reducer/thunks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import Carousel from 'react-bootstrap/Carousel';
import Banners from "../componets/Banners";


export default function Contact() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myElementRef = useRef(null); // Define a ref using useRef hook

  // Destructuring with different names to avoid conflict
  const {
    data,
    loading: bannerLoading,
    error: bannerError,
  } = useSelector((state) => state.data);

  useEffect(() => {
    dispatch(fetchBannerData());
    const myElement = document.getElementById("myElement2");
    if (myElement) {
      // Do something with the element, for example, scroll to it
      myElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Local Heros</title>
        <meta
          name="description"
          content="This is a description of your page."
        />
        <meta name="keywords" content="local heroes, community, volunteer" />
      </Helmet>
      <Header />

      {/* <section>
        <Carousel className="cor-item">
          <Carousel.Item >
           <img src={require("../constant/images/banner1.jpeg")} className="w-100" />
            <div className="carousel-caption carousel-content mt-80">
            <TypeAnimation
              sequence={[
                `The hunger pang Biryani`,
                2000,
                `The No nonsense Biryani`,
                2000,
                ` The Happy Wala Biryani`,
                2000,
                `The Biryani of Mysore`,

              ]}
              repeat={Infinity}
              wrapper="h3"
            />
          </div>
          </Carousel.Item>
          <Carousel.Item >
          <img src={require("../constant/images/box-image.png")} className="w-100" />
            <Carousel.Caption>
             
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item >
          <img src={require("../constant/images/box-image.png")} className="w-100" />
            <Carousel.Caption>
              
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

      </section> */}
      <Banners />

      <section className="breadcum-bg " id="myElement2" ref={myElementRef} style={{ maxHeight: "60px" }}></section>
      <section className="my-md-5 my-3  bg-light"></section>
      <section className="py-lg-5 ">
        <div className="container about-box cont-bg">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              <h2 className="theme-color">Contact Us</h2>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-md-6 pb-5 pb-lg-0">
              {/* <img
                src={require("../assets/images/contact-left.png")}
                className="w-100"
              /> */}
              <div className="address-sec">
                <p className="cont-info">
                  Contact Information
                </p>
                {/* <p className="say-som">
                  Say something to start a live chat!
                </p> */}
                <p className="say-som">
                  <i class="fa fa-solid fa-phone me-4"></i>
                  +91 98440 15563
                </p>
                <p className="say-som">
                  <i class="fa fa-solid fa-envelope me-4"></i>
                  info@localheros.in
                </p>
                <p className="say-som">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill me-4" viewBox="0 0 16 16">
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                  </svg>
                  3rd Floor, 9th, A Main 559,
                  9th A Main Rd, 1st Stage, Indiranagar,
                  Bengaluru, Karnataka 560008
                </p>
                <iframe
                src={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7775.741558055188!2d77.63885366137693!3d12.980115774740696!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6da67f3cbc67b53b%3A0xe4ea8e4f2f022652!2sScience%20of%20Living!5e0!3m2!1sen!2sin!4v1713761692051!5m2!1sen!2sin"  }
                height="250"
                style={{ border: "0" }}
                allowFullScreen=""
                className="col-md-12 text-end mt-md-5"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              </div>

            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <div className=" px-md-5 px-3 ">
                <form id="footer-form" className="cont-bg" style={{ paddingBottom: "15px" }}>
                  <div className="row mb-2">
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <div className="form-floating">
                        <input
                          className="form-control border-0 border-bottom rounded-0 pb-3"
                          id="fname"
                          name="name"
                          placeholder="First Name"
                          type="text"
                        />
                        <label for="fname">First Name</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <div className="form-floating">
                        <input
                          className="form-control border-0 border-bottom rounded-0 pb-3"
                          id="fname"
                          name="name"
                          placeholder="Last Name"
                          type="text"
                        />
                        <label for="lname">Last Name</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <div className="form-floating">
                        <input
                          className="form-control border-0 border-bottom rounded-0 pb-3"
                          id="email"
                          name="email"
                          placeholder="Email"
                          type="email"
                        />
                        <label for="email">Email</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <div className="form-floating">
                        <input
                          className="form-control border-0 border-bottom rounded-0 pb-3"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          type="text"
                        />
                        <label for="phone">Phone Number</label>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 md-mb-0 mb-3">
                      <div className="form-floating">
                        <textarea
                          className="form-control border-0 border-bottom rounded-0 pb-3"
                          placeholder="Write your message"
                          id="floatingTextarea2"
                          style={{ height: "100px" }}
                        ></textarea>
                        <label for="floatingTextarea2">Message</label>
                      </div>
                    </div>
                  </div>

                  <button
                    id="f_btn1"
                    className="btn button h-25"
                    type="submit"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>

        </div>
      </section>



      <Footer />
    </>
  );
}
